import intlTelInput from "intl-tel-input";

(function () {
  // Initialize Phone Input
  if (
    document.getElementById("fields_phone") &&
    window.itiInputs === undefined
  ) {
    window.itiInputs = [
      intlTelInput(document.getElementById("fields_phone"), {
        separateDialCode: true,
      }),
    ];
  }

  // Phone Validation: returns true if the country is not in the
  // countries validated list. This does not check area codes to see
  // if they are us, canada or elsewhere. If no country code is passed
  // it defaults to us.
  const isValidPhone = (value, countryCode) => {
    const country = typeof countryCode !== "undefined" ? countryCode : "us";
    const countriesValidated = ["us"];
    const validators = {
      us: value => {
        var pattern = /\D/g;
        var digitString = value.replace(pattern, "");
        while (digitString.charAt(0) === "1" || digitString.charAt(0) === "0") {
          digitString = digitString.substr(1);
        }
        const isValidLength = digitString.length === 10;
        const isValid = isValidLength;
        return isValid;
      },
    };
    return countriesValidated.includes(country)
      ? validators[country](value)
      : true;
  };
  const isValidEmail = value => {
    var pattern = /\S+@\S+\.\S+/;
    return pattern.test(value);
  };
  const drawError = (event, errorMessage, insertAfterElement) => {
    const parentElement = event.target.closest(".zenput");
    const errorElement = parentElement.querySelector(".error");
    if (!insertAfterElement) {
      insertAfterElement = event.target;
    }
    if (errorElement === null) {
      var newErrorElement = document.createElement("span");
      newErrorElement.innerHTML = errorMessage;
      newErrorElement.classList.add("error");
      insertAfterElement.parentNode.insertBefore(
        newErrorElement,
        insertAfterElement.nextSibling
      );
    } else {
      errorElement.innerHTML = errorMessage;
    }
  };
  const updateTextError = () => {
    const isRequired = event.target.getAttribute("data-required") === "true";
    const value = event.target.value;
    const errorMessage = isRequired && value === "" ? "This is required" : "";
    drawError(event, errorMessage);
    updateSubmitButton();
  };

  const updateEmailError = () => {
    const value = event.target.value;
    const isValid = isValidEmail(value);
    const errorMessage = isValid ? "" : "This should be a valid email";
    drawError(event, errorMessage);
    updateSubmitButton();
  };
  const updateRadioError = () => {
    const parentElement = event.target.closest(".zenput__field-list");
    const errorElement = parentElement.querySelector(".error");
    if (errorElement !== null) {
      errorElement.remove();
    }
    drawError(event, "");
    updateSubmitButton();
  };
  const updateDropdownError = () => {
    drawError(event, "");
    updateSubmitButton();
  };

  const updatePhoneError = () => {
    const isRequired = event.target.getAttribute("data-required") === "true";
    const value = event.target.value;
    const country = window.itiInputs[0].getSelectedCountryData();
    const isValid = isValidPhone(value, country.iso2, isRequired);
    const insertAfterElement = event.target
      .closest(".zenput")
      .querySelector(".iti");
    var errorMessage = "";
    if (isRequired && value === "") {
      errorMessage = "Phone number is required";
    } else if (!isValid && value !== "") {
      errorMessage = "This should be a valid phone number";
    }
    drawError(event, errorMessage, insertAfterElement);
    if (isValid) {
      updateHiddenPhoneInput();
    }
    updateSubmitButton();
  };
  const updateHiddenPhoneInput = () => {
    const country = window.itiInputs[0].getSelectedCountryData();
    const value = document.getElementById("fields_phone").value;
    const formattedPhone = formatPhone(value, country.iso2);
    const numberPlusCountry = `+${country.dialCode}${formattedPhone}`;
    document.getElementById("fields_phone_hidden").value = numberPlusCountry;
  };
  const formatPhone = (value, countryCode) => {
    const countriesFormatted = ["us"];
    const formatters = {
      us: value => {
        var pattern = /\D/g;
        var digitString = value.replace(pattern, "");
        while (digitString.charAt(0) === "1" || digitString.charAt(0) === "0") {
          digitString = digitString.substr(1);
        }
        return digitString;
      },
    };
    return countriesFormatted.includes(countryCode)
      ? formatters[countryCode](value)
      : value;
  };
  const updatePhonePlaceholder = () => {
    const country = window.itiInputs[0].getSelectedCountryData();
    const countryCode = country.iso2;
    const countryPlaceholders = {
      us: "(201) 555-1234",
    };
    const placeholderText = countryPlaceholders[countryCode]
      ? countryPlaceholders[countryCode]
      : "";
    const placeholderElement = document.getElementById("fields_phone");
    placeholderElement.setAttribute("placeholder", placeholderText);
  };
  const updateConsentMessage = () => {
    const smsConsentMessage = document.querySelector(
      '[data-role="sms-consent-message"]'
    );
    const smsDisabledMessage = document.querySelector(
      '[data-role="sms-disabled-message"]'
    );
    const country = window.itiInputs[0].getSelectedCountryData();
    const countryCode = country.iso2;
    const countriesSupportingSms = ["us"];
    const isSupportedCountry = countriesSupportingSms.includes(countryCode);
    if (smsConsentMessage && isSupportedCountry) {
      smsConsentMessage.style.display = "inline-block";
      smsDisabledMessage.style.display = "none";
    } else if (smsConsentMessage) {
      smsConsentMessage.style.display = "none";
      smsDisabledMessage.style.display = "inline-block";
    }
  };
  const changePhoneCountry = () => {
    updatePhonePlaceholder();
    updateConsentMessage();
    updatePhoneError();
    updateSubmitButton();
  };
  const updateSubmitButton = () => {
    const errorElements = document.querySelectorAll(
      ".error:not(.ignore-error)"
    );
    const submitButton = document.querySelector(".drip-submit-button");
    var errorMessages = "";
    errorElements.forEach(errorElement => {
      errorMessages += errorElement.innerHTML;
    });
    if (errorMessages === "") {
      submitButton.disabled = false;
    } else {
      submitButton.disabled = true;
    }
  };

  // Event Listeners
  const emailInput = document.getElementById("fields_email");
  const phoneInputs = document.querySelectorAll(".drip-phone-field");
  const dateInputs = document.querySelectorAll(".drip-date-field");
  const textInputs = document.querySelectorAll(".drip-text-field");
  const radioInputs = document.querySelectorAll(".drip-radio-input");
  const dropdownInputs = document.querySelectorAll(".drip-dropdown-field");
  emailInput.addEventListener("focusout", updateEmailError);
  phoneInputs.forEach(phoneInput => {
    phoneInput.addEventListener("focusout", updatePhoneError);
    phoneInput.addEventListener("countrychange", changePhoneCountry);
  });
  dateInputs.forEach(dateInput => {
    dateInput.addEventListener("input", updateTextError);
  });
  textInputs.forEach(textInput => {
    textInput.addEventListener("focusout", updateTextError);
  });
  radioInputs.forEach(radioInput => {
    radioInput.addEventListener("input", updateRadioError);
  });
  dropdownInputs.forEach(dropdownInput => {
    dropdownInput.addEventListener("input", updateDropdownError);
  });
  updateSubmitButton();
})();
